<template>
  <div class="container">
    <div class="card card-custom">
      <!--begin::Create-->
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">{{ $t("quicksale.name") }}</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row justify-content-start px-8 px-lg-10">
          <div class="col-md-7 col-lg-7 col-sm-12 justify-content-start mb-5">
            <div class="row px-5 justify-content-between border-bottom pb-4">
              <button
                type="button"
                :class="{ 'btn-outline-success': quicksale.type === 20 }"
                class="btn btn-pill btn-success col-md-6 col-sm-12"
                @click="changeTab(10)"
              >
                {{ $t("quicksale.buyNow") }}
              </button>
              <button
                type="button"
                :class="{ 'btn-outline-danger': quicksale.type === 10 }"
                class="btn btn-pill btn-danger col-md-6 col-sm-12"
                @click="changeTab(20)"
              >
                {{ $t("quicksale.sellNow") }}
              </button>
            </div>
            <!--begin::Form Wizard-->
            <BuyNow v-if="quicksale.type === 10" />
            <SellNow v-if="quicksale.type === 20" />
            <!--end::Form Wizard-->
          </div>
          <div class="col-md-5 col-lg-5 col-sm-12 justify-content-start">
            <div class="pb-3" v-if="quicksale.type === 10" v-html="$t('quicksale.buy_information')"></div>
            <div class="pb-3" v-else v-html="$t('quicksale.sell_information')"></div>
            <img src="/assets/media/logos/prime-trust-logo.png" />
          </div>
        </div>
      </div>
      <!--end::Create-->

      <!--begin::History-->
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">{{ $t("quicksale.history") }}</h3>
        </div>
      </div>
      <div class="card-body">
        <History />
      </div>
      <!--end::History-->
    </div>
    <SuccessModal />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SuccessModal from "./SuccessModal.vue";
import History from "./History.vue";
import BuyNow from '@/components/quickSale/BuyNow.vue'
import SellNow from '@/components/quickSale/SellNow.vue'

export default {
  name: "Quicksale",
  computed: {
    ...mapState({
      quicksale: state => state.quicksale.quicksale,
    })
  },
  components: {
    SuccessModal,
    History,
    BuyNow,
    SellNow
  },
  methods: {
    changeTab(type) {
      this.quicksale.type = type;
      this.quicksale.amount = null;
      this.quicksale.total = null;
    },
  },
};
</script>
